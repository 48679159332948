/* $(document).pjax('a', '#pjax-container'); */

$("#gmap").gmap3({
    map: {
        options: {
            center: [53.520602000213806, 12.660538999999972],
            zoom: 14,
            mapTypeId: "noPOI",
            mapTypeControl: true,
            navigationControl: true,
            scrollwheel: false,
            streetViewControl: false,
            draggable: false
        }
    },
    styledmaptype: {
        id: "noPOI",
        options: {
            name: "no POI"
        },
        styles: [
            {
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [
                    {visibility: 'off'}
                ]
            }
        ]
    },
    marker: {
        values: [
            {latLng: [53.520602000213806, 12.660538999999972]}
        ],
        options: {
            draggable: false,
            clickable: false
        }
    }
});

$(window).load(function(){
    setTimeout(function (){
        $('#jobsModal').modal('show');
    }, 2000);
});
